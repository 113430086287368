import React, { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import CSSModules from 'react-css-modules'
import styles from './templatesUnderMenu.css'
import { transS } from '../../../../../../services/helpers/lang'
import { validate } from '../../../../../../services/helpers/autocomplete'

const OverFormula = ({params, sendToFrame}) => {
  const ref = useRef(),
    [focused, setFocused] = useState(false),
    [formula, setFormula] = useState(''),
    [cursorPos, setCursorPos] = useState(0),
    {varlist} = useSelector(state => state.document),
    dictionary = varlist ? varlist.filter(el => el.type === 'number')
        .map(el => el.name) : []

  let currentIndex = 0, currentPosition = null, 
    matchingWords, lastWord, timeout
    
  useEffect(() => {
    if (!params) return
    const {top,left,pleft,pright,ptop,fontSize, lineHeight, formula} = params
    let el = ref.current, p = el.parentElement
    p.style.left = `${pleft}px`
    p.style.top = `${ptop}px`
    p.style.width = `${pright - pleft}px`
    p.style.textIndent = `${left-pleft}px`
    p.style.fontSize = fontSize
    p.style.lineHeight = lineHeight
    setFocused(false)
    setFormula(formula)
  }, [params])

  useEffect(() => {
    const handleSelectionChange = () => {
      if (ref && document.activeElement === ref.current) {
        const selection = window.getSelection()
        const cursorPosition = selection.anchorOffset
        setCursorPos(cursorPosition);
        if (!focused && transS('Fill Formula') === ref.current.textContent)
            ref.current.textContent = ''
        setFocused(true)
      } else if (ref)
        setFocused(false)
    };

    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
        ref.current.blur()
        return
    }
    if (event.key !== 'Tab') return
    event.preventDefault();
    checkInput()
  }

  const findPrefix = (s) => {
    const words = s.match(/\b\w+\b/g),
      prefix = words ? words[words.length - 1] : '',
      check = s.slice(-prefix.length)
    return check === prefix ? prefix : ''    
  }

  const findContinuation = (s) => {
    const words = s.match(/\b\w+\b/g),
      prefix = words ? words[0] : '',
      check = s.slice(0, prefix.length)
    return check === prefix ? prefix : ''    
  }

  const tryComplete = byTab => {
    const newText = ref.current.textContent,
      sel = window.getSelection(),
      pos = sel.focusOffset

    const setCursor = position => {
      const range = document.createRange()
      range.setStart(ref.current.childNodes[0], position);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
    
    if (currentPosition !== null && matchingWords.length === 1)
        return;
    
    const start = currentPosition === null ? 
      pos : currentPosition,
      beforeCursor = newText.slice(0, start),
      afterCursor = currentPosition === null ?
        newText.slice(start) : 
        newText.slice(start + lastWord.length + 1)

    if (currentPosition !== null) {
        currentIndex++
        lastWord = matchingWords[currentIndex % matchingWords.length]
        ref.current.textContent = 
          beforeCursor + lastWord + ' ' + afterCursor
        setCursor(start + lastWord.length + 1)
        return
    }
    // Находим последнее слово перед курсором
    const prefix = findPrefix(beforeCursor),
      continuation = findContinuation(afterCursor)

    // Ищем в словаре подходящее слово
    matchingWords = dictionary.filter(word => word.startsWith(prefix));
    if (matchingWords.length === 0) return; // Если подходящих слов нет, ничего не делаем
    if ((matchingWords.length > 1 || !prefix ) && !byTab )
        return
    currentIndex = matchingWords.findIndex(el => el === (prefix + continuation))
    if (continuation || currentIndex < 0) currentIndex++
    lastWord = matchingWords[currentIndex % matchingWords.length];
    currentPosition = start - prefix.length
    ref.current.textContent = 
      beforeCursor.slice(0, beforeCursor.length-prefix.length)
      + lastWord + ' ' + afterCursor.slice(continuation.length)
    setCursor(start + lastWord.length - prefix.length + 1)
  }

  const checkInput = s => {
    tryComplete(!s)
    validate(ref.current, dictionary)
    if (s)
        currentPosition = null
    sendToFrame({ c1: "overFormula", newText: ref.current.textContent})
  }

  const leaveFormula = () => {
    if (focused) return
    const current = ref.current.textContent, 
    newText = (current != formula ? current : null)
    sendToFrame({ c1: "overFormula", newText, leave: true})
  }

  return !params ? false : <div styleName="formula-div">
    <span contentEditable
        suppressContentEditableWarning
        styleName="formula"
        ref={ref}
        dangerouslySetInnerHTML={{ __html: 
          (formula || transS('Fill Formula')) }}        
        onBlur={() => setFocused(false)}
        onMouseLeave={leaveFormula}
        onKeyDown={handleKeyDown}
        onInput={checkInput}>
    </span>
  </div>
}
export default CSSModules(OverFormula, styles, {allowMultiple: true})