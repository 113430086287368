import { transS } from "./lang"

const replacer = new RegExp(`\\b(null|undefined)\\b`,'g')  

export const initAutocomplete = (el, dictionary) => {
  const regex = new RegExp(`\\b(${dictionary.join('|')})\\b`, 'g')
  let currentIndex = 0, currentPosition = null, 
    matchingWords, lastWord, savedPos = -1, lastBackspace

  const findPrefix = (s) => {
    const words = s.match(/\b\w+\b/g),
      prefix = words ? words[words.length - 1] : '',
      check = s.slice(-prefix.length)
    return check === prefix ? prefix : ''    
  }

  const findContinuation = (s) => {
    const words = s.match(/\b\w+\b/g),
      prefix = words ? words[0] : '',
      check = s.slice(0, prefix.length)
    return check === prefix ? prefix : ''    
  }

  const handleSelection = () => {
    if (el.textContent === transS('Fill Formula')) {
      setCursor(0)      
    }
  }

  const setCursor = position => {
    const sel = window.getSelection(),
      range = document.createRange()
    range.setStart(el.firstChild, position);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
  
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
        el.blur()
        return
    }
    lastBackspace = event.key === 'Backspace'
    if (el.textContent === transS('Fill Formula')
      && event.key === 'Delete') {
        event.stopPropagation()
        event.preventDefault()
    }
    if (event.key !== 'Tab') return
    event.preventDefault();
    checkInput()
  }

  const tryComplete = byTab => {
    const sel = window.getSelection(),
      pos = sel.focusOffset,
      transFormula = transS('Fill Formula'),
      oldText = el.textContent,
      newText = oldText.replace(transFormula, byTab ? ' ' : '')

    if (!oldText) {
      el.textContent = transFormula
      setCursor(0)
      return
    }
    if (newText !== oldText) {
      el.textContent = newText
      setCursor(pos)
    }

    if (pos !== savedPos) {
      currentPosition = null
      savedPos = -1
    }
    
    if (currentPosition !== null && matchingWords.length === 1)
        return;
    
    const start = currentPosition === null ? 
      pos : currentPosition,
      beforeCursor = newText.slice(0, start),
      afterCursor = currentPosition === null ?
        newText.slice(start) : 
        newText.slice(start + lastWord.length + 1)

    if (currentPosition !== null) {
        currentIndex++
        lastWord = matchingWords[currentIndex % matchingWords.length]
        el.textContent = 
          beforeCursor + lastWord + ' ' + afterCursor
        savedPos = start + lastWord.length + 1
        setCursor(savedPos)
        return
    }
    // Находим последнее слово перед курсором
    const prefix = findPrefix(beforeCursor),
      continuation = findContinuation(afterCursor)

    // Ищем в словаре подходящее слово
    matchingWords = dictionary.filter(word => word.startsWith(prefix));
    if (matchingWords.length === 0) return; // Если подходящих слов нет, ничего не делаем
    if ((matchingWords.length > 1 || !prefix ) && !byTab )
        return
    currentIndex = matchingWords.findIndex(el => el === (prefix + continuation))
    if (continuation || currentIndex < 0) currentIndex++
    lastWord = matchingWords[currentIndex % matchingWords.length];
    currentPosition = start - prefix.length
    el.textContent = 
      beforeCursor.slice(0, beforeCursor.length-prefix.length)
      + lastWord + ' ' + afterCursor.slice(continuation.length)
    savedPos = start + lastWord.length - prefix.length + 1
    setCursor(savedPos)
  }

  const checkInput = s => {
    if (!lastBackspace)
      tryComplete(!s)
    validate(el, dictionary)
    if (s)
        currentPosition = null
  }

  el.oninput = checkInput
  el.onkeydown = handleKeyDown
  el.onselectionchange = handleSelection
}

export const validate = (el, dictionary, values) => {
    if (!el) return
    const regex = new RegExp(`\\b(${dictionary.join('|')})\\b`, 'g')
    let result, val = (values ? el : el.textContent)
      .replace(regex, (match) => {
        const index = dictionary.indexOf(match);
        return values ? values[index] : index + 19;
      })

    try {
        const trick = new Function(`return ${
            val.replace(replacer,'sdfs')};`)()
        result = (trick === true || trick === false)
        if (values) return trick
    } catch (error) {
        if (values) return true
    }
    if (result) {
        el.dataset.valid = true
    } else {
        if (el.dataset.valid)
           delete el.dataset.valid
    }
    return result
}

